<template>
  <z-plate title="未评价作品列表" has-bottom-border>
    <div class="second" v-loading="tableLoading">
      <el-form class="second-form" inline>
        <el-form-item>
          <el-input v-model="detailForm.schoolName" placeholder="学校名称" clearable maxlength="50"/>
        </el-form-item>

        <el-form-item>
          <el-input v-model="detailForm.videoName" placeholder="作品名称" clearable maxlength="50"/>
        </el-form-item>

        <el-form-item>
          <el-select v-model="detailForm.channelId" placeholder="请选择" multiple clearable
    collapse-tags>
            <el-option
              v-for="item in channelList"
              :key="item.channelId"
	            :label="item.groupName + '-' + item.channelName"
	            :value="item.channelId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button icon="el-icon-search" @click="queryDetailList">搜索</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格 -->
      <el-table class="z-table" :data="page.content" style="width: 100%">
        <el-table-column type="index" label="序号" width="60"></el-table-column>

        <el-table-column prop="channelName" label="作品分类"></el-table-column>

        <el-table-column prop="videoName" label="作品名称">
          <template slot-scope="{ row }">
            <span class="color-primary cursor" @click="checkWorks(row.videoId, row.videoType, row.videoStatus, row.videoUrl, row.videoName)">{{row.videoName}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="schoolName" label="上报单位"></el-table-column>

        <el-table-column prop="userName" label="联系人"></el-table-column>

        <el-table-column prop="userPhone" label="电话"></el-table-column>
      </el-table>

      <!-- 分页 -->
	    <z-pagination
	      v-if="pagination.totalPages > 0"
	      :page.sync="pagination.page"
	      :limit.sync="pagination.size"
	      :total="pagination.totalPages"
	      @pagination="init"
	    ></z-pagination>
    </div>

    <v-check-works v-if="dialogVisible" :show.sync="dialogVisible" :data="dialogData"></v-check-works>
  </z-plate>
</template>

<script>
	import VCheckWorks from './CheckWorks'
export default {
  components: {
    VCheckWorks
  },

  data() {
    return {
    	videoUrl: '',
    	picList: [],
    	userid: sessionStorage.getItem("pingwei_userid"),
    	activityid: sessionStorage.getItem("group_activityid"),
      activeName: "first",
      tableData: [],
      page:{
      	content: []
      },
      pagination: {
        page: 0, //当前页
        size: 10, //分页条数
        totalPages: 0 //总条数
      },

      detailForm: {
        channelId: '',
        videoName: '',
        schoolName: ''
      },

      channelList: [],
      tableLoading: true,
      dialogVisible: false,
      dialogData: {}
      
    };
  },

  mounted() {
  	this.queryChannelByActivityId();
  	this.queryDetailList();
  },

  methods: {
  	//videoUrl 视频地址 
  	checkWorks(videoid,type,status,videoUrl,videoName) {
  		this.dialogData = {}
  		this.$nextTick(() => {
	  		if(type == 0){//视频类
	  			if(status != 3){
		  			this.$message({
							type: 'info',
							message: '视频正在转码中...'
						});
		  			return;
		  		}else{
		  			this.dialogData = {
		  				type: 0,
		  				url: videoUrl,
		  				name: videoName
		  			}
		  			this.dialogVisible = true;
		  		}
	  		}else if(type == 1){//图片类
	  				this.dialogVisible = true;
	  				this.loadPicListByVideoId(videoid).then(rep => {
			  			let videoname = videoName;
							if(rep.content.picList.length > 1){
								videoname += "（组图）";
							}
							
							this.dialogData = {
								type: 1,
								url: rep.content.picList,
								name: videoname
							}
	  				});
	  		}
  		})
  	},
  	loadPicListByVideoId(videoid){
  		return this.$post('/loadPicListByVideoId',{"videoid": videoid})
  	},
  	queryDetailList(){
  		this.pagination.page = 0;
  		this.init();
  	},
  	//根据活动id查询分类
  	queryChannelByActivityId(){
  		this.$post('/queryChannelById',{"activityId": this.activityid}).then(rep => {
  			this.channelList = rep.content.list;
			})
  	},
    init() {
    	var channelid = '';
    	if(this.detailForm.channelId){
    		channelid = this.detailForm.channelId.join();
    	}
    	let {pagination} = this;
      this.tableLoading = true;
      this.$post('/queryPingJiaVideoListByActivityid',{
      	userid: this.userid,
      	activityid: this.activityid,
      	channelId: channelid,
      	videoName: this.detailForm.videoName,
      	schoolName: this.detailForm.schoolName,
      	page: pagination.page,
      	size: pagination.size
      }).then(rep => {
       	this.page = rep.content.page;
      	pagination.page = this.page.number;
      	pagination.totalPages = this.page.totalElements;
        this.tableLoading = false;
     	})
    },
  }
};
</script>

<style lang="scss" scoped>
.first {
  & > section {
    padding: 15px 20px;
  }
}

.second-form {
  padding: 15px 20px 0;
}

</style>